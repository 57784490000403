import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';

import Fab from '@mui/material/Fab';



import { Link } from 'gatsby';
import { IoMenuOutline } from "@react-icons/all-files/io5/IoMenuOutline";
import { IoMail } from "@react-icons/all-files/io5/IoMail";
import { IoHome } from "@react-icons/all-files/io5/IoHome";
import { GiBonsaiTree } from "@react-icons/all-files/gi/GiBonsaiTree";
import { IoEasel } from "@react-icons/all-files/io5/IoEasel";
import { IoPeople } from "@react-icons/all-files/io5/IoPeople";
import { IoReaderOutline } from "@react-icons/all-files/io5/IoReaderOutline";


import AniLink from "gatsby-plugin-transition-link/AniLink"


const links1 = [
            {text: 'Home', title: "Home", href : '/', icon : <IoHome/>  },
            {text: 'Team', title: "Team", href : '/team', icon : <IoPeople/>  },
            {text: 'Recipe', title: "Method", href : '/recipe',icon : <IoEasel/>  },
            {text: 'Superclans', title: "Families", href : '/superclans',icon : <GiBonsaiTree/>  },
            {text: 'Contact', title: "Contact", href : '/contact',icon : <IoMail/>  },

  
            {text: 'Privacy Policy', title: "Privacy Policy", href : '/privacy-policy', icon : <IoReaderOutline/>  },
            {text: 'Terms and Conditions', title: "Terms and Conditions", href : '/terms-and-conditions',icon : <IoReaderOutline/>  },

              ]; 


 








const linksA = links1.map((link, i) => <ListItem button>  {link.icon}   <Link rel='nofollow noreferrer' title={link.title} to={link.href}><AniLink  hex='#fff' paintDrip to={link.href}>{link.text}</AniLink></Link>  <Divider /></ListItem>     
 );  







export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });





  const [checked, setChecked] = React.useState(true);

  const handleSwitch = (event) => {


    setChecked(event.target.checked);





  };







  const [open, setOpen] = React.useState(true);




  const handleClick = () => {setOpen(!open); };





  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     




      <List> {linksA} </List>










      


    </Box>
  );

  return (
    <div>



    
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>



          
 <Fab onClick={toggleDrawer(anchor, true)} color="primary" aria-label="add">
<IoMenuOutline></IoMenuOutline>
      </Fab>





          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
