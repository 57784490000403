import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

export default function PositionedSnackbar() {



const isBrowser = typeof window !== "undefined";

const [state, setState] = React.useState({open: false, vertical: 'top', horizontal: 'center', });


if (isBrowser) {



  const { vertical, horizontal, open } = state;

  const handleOpen = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });

 localStorage.setItem("ELIGLOBAL_Consent", "true");
  };


var privacyConsent = localStorage.getItem("ELIGLOBAL_Consent");


if ( privacyConsent != 'true' ) {setTimeout( handleOpen({ open:true,vertical: 'top', horizontal: 'right'}),2000);


  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={
        ' This website uses cookies as well as the browsers\' local database to improve usability. By continuing you consent to the usage of cookies, local storage and that your data might be stored in different countries, in accordance with our Privacy Policy as well as our Terms and Conditions. '
        }
        key={vertical + horizontal}
         action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}> AGREED 
            </Button>
            
          </React.Fragment>
        }
      />
    </div>
  );

} else return null

} else return null

}