import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
    import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { IoMailOutline } from "@react-icons/all-files/io5/IoMailOutline";
import { IoLogoTiktok } from "@react-icons/all-files/io5/IoLogoTiktok";
import { IoLogoInstagram } from "@react-icons/all-files/io5/IoLogoInstagram";




export default function MultilineTextFields() {


const [showSend, setShowSend] = useState(0);


const handleChange = () => {

if (showSend==0) {setShowSend(true); }
if (showSend==1) {setShowSend(false); }


};


  return (


        <div className="global-wrapper">

    <Box
      component="form"
  className='contactBox'
      noValidate
      autoComplete="off"
    >

                <form id="contact-form" className="contact__form animate__animated fadeIn" method="post" action="https://getform.io/f/17c38e44-5bb7-442d-a445-465dc25ad635">

<Grid container spacing={2}>

  <Grid item md={6} xs={12}>

        <TextField
          name="name" id="name" type="text" className="form-control"
            floatingLabelText="Name"
          label="Please tell us your name"
          multiline
          maxRows={4}
          variant="filled"
        />

</Grid>
  <Grid  className='animate__animated fadeIn' item md={6} xs={12}>

        <TextField
           name="email" 
           id="email" 
           type="email" 
           className="form-control"
            floatingLabelText="Email"
          label="Kindly provide an email address"
          multiline
          variant="filled"
        />

</Grid>

  <Grid  item xs={12}>


        <TextField
          name="message" id="message" className="form-control"
          label="Please tell us about what you need"
          multiline
          rows={4}
          variant="filled"
        />
      


</Grid>


  <Grid className='checksandbalances' item xs={12}>




  <FormControlLabel required control={<Switch        onChange={handleChange}      />} label="I accept the terms and conditions." />



</Grid>








  <Grid item xs={12}>


   <div id='sendHolder' class="text-center">


   
    {showSend ? (


    <Button endIcon={<IoMailOutline />} id='sendButton' className={showSend ? 'buttonActive' : null} name="submit" type="submit" value="Send" variant="outlined">
        Send
      </Button>


      ) : (<div></div>)}










</div>


<div className='socialBlock'>
<a className='socialLink' title='' target='_blank' href="https://tiktok.com/@eliglobal_magdin"><IoLogoTiktok /></a>
<a className='socialLink' title='' target='_blank' href="https://instagram.com/eliglobal_magdin"><IoLogoInstagram /></a>
</div>


</Grid>
</Grid>



</form>







    </Box>
     </div>  
  );
}