import * as React from "react"
import { Link } from "gatsby"

import ContactForm from "../components/contact"
import CookieNotice from "../components/cookienotice"
import MenuSwipe from "../components/menuswipe"

import AppBar from '@mui/material/AppBar';
import { IoHomeSharp } from "@react-icons/all-files/io5/IoHomeSharp";




import AniLink from "gatsby-plugin-transition-link/AniLink"



const Layout = ({ location, title, children }) => {


  return (
      
<div >





      <main>{children}



      </main>

<CookieNotice />


<span className='copyright'>© House Confucius</span>


<MenuSwipe/>




      <div className='menubar' >


 <Link className='home' to='/' activeClassName='activeTab'><AniLink   hex='#fff' paintDrip to="/"><IoHomeSharp/></AniLink></Link>
 <Link to='/team' activeClassName='activeTab'><AniLink   hex='#fff' paintDrip to="/team">Team</AniLink></Link>
 <Link to='/recipe' activeClassName='activeTab'><AniLink  hex='#fff' paintDrip to="/recipe">Recipe</AniLink></Link>
 <Link to='/superclans' activeClassName='activeTab'><AniLink  hex='#fff' paintDrip to="/superclans">Superclans</AniLink></Link>
  <Link to='/contact' activeClassName='activeTab'><AniLink  hex='#fff' paintDrip to="/contact">Contact</AniLink></Link>





      </div>



















  </div>
  )
}

export default Layout
